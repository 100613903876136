<template>
  <div v-if="categories.length > 0" class="categories-links">
    <h2 v-if="ranking.type !== 'deals'">{{ $t('pages.reviews') }}</h2>
    <div class="category-link-wrapper">
      <div
        v-for="(category, index) in categories"
        :key="index"
        class="category-link"
        @click="select($event, category)"
      >
        <a :href="category.url">
          <span v-if="category.image" class="image-wrapper">
            <TheImage
              :image="category.image"
              :alt="category.name"
              :lazy="false"
              profile="thumb-sm"
            />
          </span>
          <CategoryIcon v-else :slug="category.slug" />
          <span>{{ category.name }}</span>
        </a>
        <div class="category-link-children" v-if="category.links && category.links.length > 0">
          <span v-for="(link, lidx) in category.links">
            <a :href="link.url">{{ link.name }}</a
            ><span v-if="lidx + 1 < category.links.length"> | </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRankingStore } from "~/stores/ranking";

const CategoryIcon = defineAsyncComponent(() => import('~/components/icons/categories/CategoryIcon'));

const ranking = useRankingStore();

const props = defineProps({
  selector: {
    type: Boolean,
    default: false,
  },
  categories: {
    type: Array,
    required: true,
  },
});

const emits = defineEmits(['selected']);

function select(event, category) {
  if (!props.selector) {
    return;
  }
  event.preventDefault();
  emits('selected', category);
}
</script>

<style lang="scss" scoped>
.categories-links {
  width: 100%;
  padding: 0;
  h2 {
    margin-top: 0;
    width: 100%;
  }
  h3 {
    padding-bottom: 15px;
    font-size: 30px;
    font-weight: 300;
    @media (max-width: $break_md_under) {
      font-size: 25px;
    }
  }
}

.category-link-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;

  .category-link {
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    padding-bottom: 10px;
    border-radius: 2px;
    width: 168px;
    text-align: center;
    cursor: pointer;

    & > a {
      color: $black-link-color;
      display: block;

      &:hover {
        text-decoration: underline;
      }

      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1.5;
        margin-bottom: 8px;
      }
    }

    svg {
      display: block;
      width: 100px;
      height: 87px;
      margin: 20px auto 8px;
    }

    & > span {
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      margin: 0 auto;
      text-align: center;
    }

    .category-link-children {
      font-size: 14px;
      white-space: nowrap;
      text-align: center;

      a {
        color: $black;
      }

      a:focus,
      a:hover {
        color: #555;
      }
    }
  }

  @media (min-width: $break_sm_upper) and (max-width: $break_md_under) {
    gap: 20px;
    .category-link {
      width: calc(25% - 15px);
    }
  }
  @media (max-width: $break_sm_under) {
    gap: 20px;
    .category-link {
      width: calc(33.3333% - 15px);
    }
  }
  @media (max-width: $break_xs_under) {
    gap: 10px;
    .category-link {
      width: calc(50% - 5px);
    }
  }
  @media (max-width: 319px) {
    flex-direction: column;

    .category-link {
      width: 100%;
    }
  }
}
</style>